.fc .fc-timegrid-body {
  overflow-y: hidden !important;
}



.fc .fc-scroller-liquid-absolute {
  overflow: hidden !important;
}

.fc-timegrid-event {
  overflow: hidden;
}

.fc-event-inline {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.fc-timegrid-col-events {
  margin: 0 !important;
}
.fc-daygrid-event-dot {
  border: calc(8px / 2) solid #3788d8;
  border-radius: calc(8px / 2);
  box-sizing: content-box;
  height: 0px;
  margin: 0px 4px;
  width: 0px;
}

.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px;
}

.fc-event-title {
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: 700;
  min-width: 0px;
  overflow: hidden;
}